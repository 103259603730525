import React from 'react';
import { Typography } from '@mui/material';
import { useUserTestTasks } from '../../../../../hooks';
import { TextButton } from '../../../../../components';
import { TestTaskType } from 'src/constants';

const NotSupportedByCompensationMessage = () => {
  const { passedTestTasks } = useUserTestTasks();
  const latestPassedTestTask = passedTestTasks?.[0];

  const handleMailToCompensation = () => {
    const email = 'careers@agileengine.com';
    const subject = "Let's Revisit My Compensation";

    const encodedSubject = encodeURIComponent(subject);

    const mailtoLink = `mailto:${email}?subject=${encodedSubject}`;

    window.location.href = mailtoLink;
  };

  if (latestPassedTestTask?.testTaskType === TestTaskType.INTERNAL) {
    const handlePingInChat = () => {
      window.smartsupp('chat:open');
    };

    return (
      <>
        <Typography
          variant="body1"
          data-testid="not-supported-by-compensation-text"
        >
          Our team would be excited to see you join our ranks, but the
          compensation you've stated is outside our current range. Would you be
          open to discussing compensation and strategies for reaching your
          desired level over time?
        </Typography>
        <TextButton onClick={handleMailToCompensation}>Contact Us</TextButton>
        <TextButton onClick={handlePingInChat} underlined>
          Let us know!
        </TextButton>
      </>
    );
  }
  return (
    <>
      <Typography
        variant="body1"
        data-testid="not-supported-by-compensation-text"
      >
        Our team would be excited to see you join our ranks, but the
        compensation you've stated is outside our current range. Would you be
        open to discussing compensation and strategies for reaching your desired
        level over time?
      </Typography>
      <TextButton onClick={handleMailToCompensation}>Contact Us</TextButton>
      <Typography variant="body1">
        Alternatively, we engage with 2-3 new clients every month, so there's
        always a chance we will have a project matching your expectations in the
        future. We would be happy to reconnect once we can offer you an
        opportunity of this kind.
      </Typography>
      <Typography variant="body1">Let’s keep in touch!</Typography>
    </>
  );
};

export default NotSupportedByCompensationMessage;
