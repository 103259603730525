import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { testTasksStoreKey } from './testTasks.const';
import {
  createUserTestTaskAPI,
  finishQuizTestTaskAPI,
  getAvailableTestTasksPath,
  getCodilityUserSessionAPI,
  postUserTestTaskSimulationResultsAPI,
  submitQuizAnswerAPI,
  testTaskReminderAPI,
  testTaskReminderDateAPI,
  uploadInternalTestTaskResolutionAPI,
} from '../../constants/apiEndpoints';
import {
  AvailableTestTaskWithPositionsData,
  CodilitySession,
  IInternalTestTaskResolution,
  ISetReminderPayload,
  ISubmitQuizAnswerRequest,
  IUserTestTask,
} from '../../types';
import { TestTaskType, simulatedTTString } from '../../constants';
import { fetchUser } from '../../redux/user';
import { notificationAlert } from '../../redux/notifications';
import { formatErrorMessage } from '../../utils';

export const getAvailableTestTasks = createAsyncThunk(
  `${testTasksStoreKey}/getAvailableTestTasks`,
  async () => {
    const response = await axios.get<AvailableTestTaskWithPositionsData[]>(
      getAvailableTestTasksPath(),
    );
    return response.data;
  },
);

export const createUserTestTask = createAsyncThunk(
  `${testTasksStoreKey}/createUserTestTask`,
  async (
    data: {
      id: string;
      type: TestTaskType;
      simulated?: boolean;
      isRequiredForUserPosition: boolean;
    },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post<IUserTestTask>(
        createUserTestTaskAPI(),
        data,
      );

      if (
        response.data?.link &&
        !response.data?.link?.startsWith(simulatedTTString)
      ) {
        window.location.replace(response.data.link);
      } else {
        await thunkAPI.dispatch(fetchUser());
        await thunkAPI.dispatch(getAvailableTestTasks());
      }

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const updateSimulatedTT = createAsyncThunk(
  `${testTasksStoreKey}/updateSimulatedTT`,
  async ({ id, score }: { id: string; score: number }, thunkAPI) => {
    try {
      const response = await axios.post<IUserTestTask>(
        postUserTestTaskSimulationResultsAPI(id),
        {
          score,
        },
      );

      if (response.status === 200) {
        await thunkAPI.dispatch(fetchUser());
        await thunkAPI.dispatch(getAvailableTestTasks());
      }

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const getCodilityResults = createAsyncThunk(
  `${testTasksStoreKey}/getCodilityResults`,
  async (userTestTaskId: string, thunkAPI) => {
    const response = await axios.get<CodilitySession>(
      getCodilityUserSessionAPI(userTestTaskId),
    );

    const userSession = response.data;
    if (
      userSession.start_date &&
      userSession.close_date &&
      userSession.evaluation.result !== null
    ) {
      await thunkAPI.dispatch(fetchUser());
      await thunkAPI.dispatch(getAvailableTestTasks());
    }

    return response.data;
  },
);

export const submitQuizAnswer = createAsyncThunk(
  `${testTasksStoreKey}/submitQuizAnswer`,
  async ({ userTestTaskId, answer }: ISubmitQuizAnswerRequest) => {
    const response = await axios.post<IUserTestTask>(
      submitQuizAnswerAPI(userTestTaskId),
      {
        answer,
      },
    );

    return response.data;
  },
);

export const finishQuizTestTask = createAsyncThunk(
  `${testTasksStoreKey}/finishQuizTestTask`,
  async (userTestTaskId: string, thunkAPI) => {
    try {
      const response = await axios.post<IUserTestTask>(
        finishQuizTestTaskAPI(userTestTaskId),
      );

      await thunkAPI.dispatch(fetchUser());
      await thunkAPI.dispatch(getAvailableTestTasks());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const uploadInternalTestTaskResolution = createAsyncThunk(
  `${testTasksStoreKey}/uploadInternalTestTaskResolution`,
  async (info: IInternalTestTaskResolution, thunkAPI) => {
    try {
      const { onUploadProgress } = info;
      delete info.onUploadProgress;

      if (typeof info.resolutionFiles === 'string') {
        delete info.resolutionFiles;
      }

      const form = new FormData();
      Object.entries(info).forEach(([key, value]) => {
        if (value) {
          if (Array.isArray(value)) {
            for (const file of value) {
              form.append(key, file);
            }
          } else {
            form.append(key, value);
          }
        }
      });

      const response = await axios.post<IUserTestTask>(
        uploadInternalTestTaskResolutionAPI(info.userTestTaskId),
        form,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
          onUploadProgress,
        },
      );

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const sendTestTaskReminder = createAsyncThunk(
  `${testTasksStoreKey}/sendTestTaskReminder`,
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(testTaskReminderAPI());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);

export const setTestTaskReminderDate = createAsyncThunk(
  `${testTasksStoreKey}/setTestTaskReminderDate`,
  async (
    { testTaskReminderDate, testTaskType }: ISetReminderPayload,
    thunkAPI,
  ) => {
    try {
      const response = await axios.post(testTaskReminderDateAPI(), {
        testTaskReminderDate,
        testTaskType,
      });
      thunkAPI.dispatch(fetchUser());

      return response.data;
    } catch (err: any) {
      thunkAPI.dispatch(
        notificationAlert(formatErrorMessage(err), { variant: 'error' }),
      );
      throw new Error(err);
    }
  },
);
